.modal-content {
    padding: 20px;
}

.check-size-table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.check-size-table, tr, td {
    border: 1px solid black;
    border-collapse: collapse;
}

.check-size-table td {
    text-align: center;
    padding: 10px 20px;
}

.table-header {
    background: #000;
    color: #fff;
}

.obs-check-site {
    color: #838383;
    font-size: 11px;
}