.loading {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #8a8a8a;
    opacity: 0.7;
    z-index: 9998;
}

.spinner {
    /* border: 6px solid #e5e5e5;
    border-top-color: #51d4db;
    height: 50px;
    width: 50px;
    border-radius: 50%; */
    animation: is-rotating 1s infinite;
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}