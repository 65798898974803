.post-review-form.form-box {
    max-width: none;
}

.post-review-form .title {
    padding: 10px 0;
}

.post-review-form .form-input {
    margin: 20px 0;
}

.post-review-form .input-box {
    height: auto;
}

.stars-input {
    display: flex;
    justify-content: space-between;
}

.stars-input .review-buttons {
    display: flex;
    gap: 8px;
}

.stars-input .review-buttons button {
    background: none;
    border: none;
    cursor: pointer;
}

.stars-input {
    margin-bottom: 10px;
}

.stars-input .review-buttons button i {
    font-size: 30px;
}

.stars-input .review-note {
    font-size: 20px;
}   

.input-box .error-message {
    color: #ff4e4e;
    font-size: 15px;
}