.static-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

.static-info-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 2000px;
    width: 1000px;
    /* min-width: 70%; */
}

.static-info-container .form-input {
    margin: 20px !important;
}

.static-info-column {
    width: 50%;
}

.static-info-title {
    padding: 0 20px;
}

.static-info-message {
    padding: 0 20px;
}

.channels-title {
    padding: 20px;
    display: flex;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.contact-items {
    /* padding-top: 50px; */
}

.contact-info-item {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

@media (max-width: 1100px) {
    .static-info-column {
        width: 100%;
    }
}