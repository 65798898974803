.btn-form.create-address-btn {
    margin-bottom: 20px;
}

.checkout-header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    background: #000;
    padding: 20px;
    z-index: 2;
}

.checkout .logo {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: auto;
}

.checkout-header-info {
    color: #fff;
}

.checkout-header-info i {
    color: #5dd3ab;
}

.checkout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

.checkout-content .form-box {
    align-items: flex-start;
    padding-top: 30px;
    height: auto;
}

.checkout-content .checkout-column .form-box {
    max-width: none;
}

.checkout-button {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.checkout-button button {
    display: block;
    background: #000;
    width: 100%;
    max-width: 360px;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    border-radius: 50px;
    font-size: 1rem;
}

.checkout-wrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: 1300px;
}

.checkout-column {
    width: 65%;
}

.checkout-column.cart-items {
    display: flex;
    position: relative;
    width: 35%;
    height: 100vh;
    padding-top: 30px;
    background: #f5f5f5;
    height: calc(100vh - 100px);
}

.checkout-column.cart-items .cart-item-list {
    height: calc(100% - 200px);
}

.checkout-column.cart-items .cart-footer {
    height: 200px;
}

.address-form {
    padding: 0 20px;
}

.checkout-address-title {
    font-weight: bold;
    padding: 0 20px;
}

.address-list {
    padding: 0 20px;
}

.address-item {
    display: block;
    border: 1px #e4e4e4 solid;
    margin: 15px 0;
}

.address-item .address-item-content {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 20px;
}

.address-item .address-item-form {
    width: 100%;
    border: 1px #e4e4e4 solid;
}

.address-item .address-item-form .form-box {
    padding-top: 0;
    background: #f5f5f5;
}

.address-item .address-item-form .form-box .form-input {
    margin: 20px;
}

.payment-item .form-box .form-input {
    margin: 20px;
}

.inline-buttons {
    display: flex;
    gap: 20px;
}

.inline-buttons button {
    margin-top: 0;
}

.inline-buttons .btn.light {
    background: #8b8b8b;
}

.inline-fields {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
    margin: 20px 0;
}

.inline-fields .form-input {
    width: 100%;
    margin: 0 10px !important;
}

.address-item .change-address-item {
    cursor: pointer;
}

.address-item-input {
    margin-right: 20px;
}

.address-item .address-item-content button {
    background: none;
    border: none;
    color: rgb(0, 0, 214);
}

.shipping-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.shipping-item {
    width: 50%;
    padding: 10px;
    cursor: pointer;
}

.shipping-item .content {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px #e4e4e4 solid;
}

.radio-item-title {
    display: block;
    font-weight: bold;
    color: rgb(70, 70, 70);
}

.radio-item-info {
    display: block;
    font-size: 14px;
    color: rgb(122, 122, 122);
}

.shipping-item-input {
    margin-right: 20px;
}

.checkout-buttons {
    padding: 20px;
}

.checkout-buttons .inline-buttons {
    justify-content: space-between;
}

.checkout-buttons .inline-buttons.unique {
    justify-content: right;
}

.checkout-buttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
}

.payment-title {
    font-weight: bold;
    padding: 0 20px;
}

.payment-list {
    padding: 0 20px;
}

.payment-item {
    display: block;
    border: 1px #e4e4e4 solid;
    margin: 15px 0;
    padding: 0;
}

.payment-item-input {
    margin-right: 20px;
}

.payment-item .payment-item-content {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 20px;
}

.payment-item .colapse-content {
    width: 100%;
    border: 1px #e4e4e4 solid;
    padding: 0;
    background: #f5f5f5;
    font-weight: lighter;
}

.payment-item .colapse-content .form-box {
    padding: 0;
    background: #f5f5f5;
}

.payment-item .colapse-content .payment-info {
    padding: 20px;
}

.payment-item .colapse-content .payment-info p {
    font-size: 14px;
    padding-bottom: 10px;
}

.checkout-success {
    padding: 30px 0;
}

.checkout-title {
    padding: 30px 20px;
}

.checkout-title h3 {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 20px;
    padding: 5px;
    text-align: center;
}

.checkout-success .checkout-title i {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 120px;
    padding: 10px;
    color: #2fb789;
}

.checkout-in-analise {
    max-width: 600px;
    padding-bottom: 20px;
}

.checkout-in-analise p {
    text-align: center;
}

.check-status {
    display: flex;
    justify-content: center;
}

.check-status a {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 234px;
}

.checkout-payment {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.payment-info-data {
    width: 100%;
}

.payment-image {
    display: flex;
    justify-content: center;
    padding: 0 20px;
}

.payment-image i {
    color: #ffafaf;
    font-size: 120px;
}

.payment-image img {
    display: flex;
    max-width: 300px;
    border: #000 2px solid;
    border-radius: 15px;
}

.payment-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.payment-link a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #878787;
    border-bottom: #878787 1px solid;
    font-size: 15px;
}

.payment-link a i {
    width: 20px;
    font-size: 20px;
    padding: 3px;
}

.button-boleto-download {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.button-boleto-download .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    padding: 0 30px;
    margin: 0;
}

.payment-info h4 {
    text-align: center;
    padding-bottom: 20px;
}

.back-to-site {
    display: flex;
    width: 100%;
    justify-content: center;
}

.back-to-site a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #878787;
}

.back-to-site a i {
    width: 25px;
    font-size: 20px;
}

@media (max-width: 1000px) {
    .checkout-column {
        width: 100% !important;
    }

    .checkout-column .form-box {
        height: auto;
    }

    .checkout-buttons .inline-buttons.unique {
        justify-content: center;
    }

    .checkout-buttons .inline-buttons.unique button {
        max-width: 100%;
    }

    .checkout-info .cart-footer {
        position: relative;
    }

    .inline-fields.break {
        display: block;
        padding-right: 30px;
    }

    .inline-fields.break .form-input {
        margin: 20px 10px;
    }
}