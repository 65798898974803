.product-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
}

.product-details-column {
    width: 50%;
    display: flex;
}

.product-details-column:first-child {
    justify-content: flex-end;
}

.product-image {
    position: relative;
    /* display: flex; */
    width: 100%;
    max-width: 650px;
}

.product-image img {
    width: 100%;
    padding: 10px;
    /* max-width: 700px;
    max-height: 720px; */
}

.product-details-info {
    width: 100%;
    line-height: normal;
    padding: 50px 0;
    margin-left: 50px;
}

.product-details-info span {
    display: block;
    padding: 0;
    line-height: normal;
}

.details-info-category {
    font-size: 25px;
    color: #848484;
}

.details-info-title {
    font-size: 35px;
    font-weight: bold;
}

.details-info-price {
    font-size: 35px;
    font-weight: bold;
}

.details-info-condition {
    color: #545454
}

.product-options {
    margin: 20px 0 20px 0;
}

.product-options span {
    font-weight: bold;
}

.product-options ul {
    list-style: none;
    display: flex;
    padding-top: 10px;
    padding-bottom: 30px;
}

.product-details .product-options ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 15px;
    /* background: red; */
    margin-right: 8px;
    border: 2px black solid;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
}

.product-details ul li.active {
    background: black;
    color: white
}

.check-size a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #545454;
    margin-left: -7px;
    max-width: 250px;
}

.check-size a img {
    width: 35px;
    height: 35px;
    filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(166deg) brightness(94%) contrast(91%);
}

.btn-send-cart {
    background: #000 !important;
    width: 50%;
    border-radius: 50px;
    height: 50px;
}

.colapses {
    display: block;
    width: 100%;
}

.product-details-content {
    display: block;
}

.product-details-header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    color: #000;
}

.absolute-line {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 3.5px;
    z-index: -1;
}

.absolute-line hr {
    height: 1.8px;
    background-color: #e0e0e0;
    border: none;
}

.colapse-handler {
    cursor: pointer;
    font-weight: bold;
    padding: 15px;
    background: #fff;
    /* z-index: 5; */
}

.colapse-handler hr {
    height: 1.8px;
    background-color: #000;
    border: none;
    width: 0;
    transition: 0.4s;
}

.colapse-handler.active hr {
    width: 100%;
}

.colapse-handler:hover hr {
    width: 100%;
}

.colapse-content {
    display: none;
    padding: 0 20%;
}

.colapse-content.active {
    display: block;
    width: 100%;
}

.colapse-text {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.colapse-text p {
    margin-bottom: 15px;
}

.review-title {
    display: flex;
    width: 100%;
    justify-content: center !important;
    align-items: center;
    font-size: 50px;
    font-weight: bold;
    /* margin-bottom: 20px; */
}

.review-title span {
    margin-right: 10px;
}

.review-title p {
    font-size: 15px;
    color: #848484;
}

.review-items {
    display: block;
    width: 100%;
}

.review-item {
    display: block;
    width: 100%;
    padding: 20px 0;
}

.review-item .rate {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
}

.star {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c5c5c5;
    padding: 0;
    margin: 0;
}

.star.active {
    color: #ffc700;
}

.review-item .rate .rate-date {
    display: flex;
    justify-content: right;
    color: #c5c5c5;
    font-weight: 100;
    font-size: 16px;
}

.review-item .rate .text-icon {
    width: auto;
}

.review-item .comment {
    display: flex;
    width: 100%;
}

.related-products-title {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 20px;
}

.related-products {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 10px;
    justify-content: center;
}

@media (max-width: 1100px) {
    .product-details-column {
        width: 100%;
    }
    
    .product-image {
        max-width: none;
    }

    .product-details-info {
        margin-left: 0;
        padding-top: 20px;
    }

    .btn-send-cart {
        width: 100%;
    }

    .review-title {
        font-size: 35px;
    }

    .review-title i {
        font-size: 35px;
    }

    .colapse-content {
        padding: 0;
    }

    .related-products-title {
        font-size: 1rem;
    }
}