.maintenance {
    display: flex;
    background: #000;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.maintenance .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.70;
}

.maintenance-content {
    position: absolute;
    width: 100%;
}

.maintenance-image {
    display: flex;
    justify-content: center;
}

.maintenance-logo {
    max-width: 250px;
    margin-bottom: 30px;
    border-radius: 50%;
    outline: black 2px solid;
    outline-offset: -1px;
}

.maintenance-text {
    color: #fff;
}

.maintenance-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 26px;
    text-align: center;
}

.maintenance-title i {
    font-size: 40px;
}

.maintenance-title h2 {
    font-family: 'Cordel Encarnado II';
}

.maintenance-text p {
    display: flex;
    padding: 5px 20px;
    justify-content: center;
    font-family: 'Cordel Groteska';
}

.maintenance-text label {
    display: flex;
    padding: 5px 20px;
    justify-content: center;
}

.maintenance-text p:first-child {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
}

@media (max-width: 600px) {
    .maintenance-logo {
        max-width: 200px;
    }

    .maintenance-title {
        font-size: 16px;
    }
}
