/********* GLOBAL *********/

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 12px;
}

body::-webkit-scrollbar-track {
    background: #f0f0f0
}

body::-webkit-scrollbar-thumb {
    background-color: #848484;
    border-radius: 20px;
    border: 3px solid #f0f0f0
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
}

/* Removes default focus */
input[type="range"]:focus {
    outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
    background-color: #949292;
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    margin-top: -12px;
    /* Centers thumb on the track */

    /*custom styles*/
    background-color: #000;
    height: 2rem;
    width: 1rem;
    border-radius: 4px;
}

input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid #949292;
    outline: 3px solid #949292;
    outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
    background-color: #949292;
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
    border: none;
    /*Removes extra border that FF applies*/
    border-radius: 0;
    /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: #000;
    height: 2rem;
    width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid #949292;
    outline: 3px solid #949292;
    outline-offset: 0.125rem;
}

::placeholder {
    color: #fff;
    opacity: 0.8;
}

.no-scroll {
    width: 100%;
    overflow: hidden;
}

.block-toutch {
    display: block;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: #000;
    visibility: hidden;
    transition: 0.2s all ease;
    opacity: 0;
}

.block-toutch.active {
    visibility: visible;
    opacity: 0.5;
}

.list-empty {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    padding: 50px 20px;
    color: #787878;
}

.list-empty span {
    display: block;
    font-size: 20px;
    text-align: center;
    padding: 0;
}

.list-empty i {
    display: block;
    font-size: 120px;
    text-align: center;
    padding: 20px;
}

.fade-in {
    transition: opacity 1s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

.token-input-box {
    display: flex;
    width: 100%;
    justify-content: center;
}

.token-input-fields {
    display: flex;
    width: 100%;
    max-width: 330px;
    justify-content: space-between;
}

.token-input {
    width: 40px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    border: 2px #8f8f8f solid;
    border-radius: 5px;
    color: #606261;
}

.token-input.invalid {
    border: 2px solid #ff4e4e;
}

.token-input.invalid::placeholder {
    color: #ff4e4e;
}

/********* HEADER *********/
.header {
    /* width: 100%; */
    background: #000;
    padding-bottom: 100px;
}

.info {
    z-index: 100;
    width: 100%;
    height: 30px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    color: #d2d1d1;
    position: fixed;
}

header {
    z-index: 100;
    position: fixed;
    top: 30px;
    width: 100%;
    height: 70px;
    background-color: #000000;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0.1px 9px black;
}

.header-top-text {
    position: absolute;
}

ul {
    margin: 0px;
    padding: 0px;
}

a {
    color: #fff;
    text-decoration: none;
    font-family: sans-serif;
    cursor: pointer;
}

.logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    z-index: 100;
}

.logo a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.logo h1 {
    font-family: 'Cordel Encarnado II';
    font-size: 1.6rem;
}

.nav {
    /* width: 43%; */
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: flex-end;
    z-index: 101;
}

header .buttons {
    display: inline-flex;
    align-items: center;
    z-index: 101;
    /* margin-left: 35px; */
}

header .buttons .btn-cart {
    display: flex;
    justify-content: center;
}

.menu {
    display: flex;
    list-style: none;
    gap: .5rem;
    touch-action: none;
}

.menu a {
    display: block;
    padding: .5rem;
}

.menu .dropdown-options::-webkit-scrollbar {
    width: 12px;
}

.menu .dropdown-options::-webkit-scrollbar-track {
    background: #f0f0f0
}

.menu .dropdown-options::-webkit-scrollbar-thumb {
    background-color: #848484;
    border-radius: 20px;
    border: 3px solid #f0f0f0
}

.btn-mobile {
    display: none;
}

.text-icon {
    display: inline-flex;
    justify-content: left;
    gap: 8px;
    width: 100%;
}

.dropdown-btn {
    color: #fff;
    padding: .5rem;
    cursor: pointer;
}

.dropdown-icon {
    transition: .3s;
}

.dropdown-options {
    display: none;
    position: absolute;
    overflow: auto;
    border-radius: 8px;
    background: #fff;
    color: #000000;
    padding: 10px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 20%);
}

.dropdown-options a {
    color: #000000;
    border-radius: 8px;
}

.dropdown-options a:hover {
    color: #fff;
    background: #000000;
}

.dropdown:hover .dropdown-options {
    display: block;
}

.dropdown:hover .dropdown-icon {
    transform: rotate(180deg);
}

.btn {
    color: #fff;
    /* display: flex; */
    /* padding: 1rem 1rem; */
    font-size: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    gap: .5rem;
}

.btn-user-mobile {
    display: none !important;
}

.badge-cart-items {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 43px;
    right: 10px;
    font-size: 12px;
    width: 15px;
    height: 15px;
    background: #fff;
    color: #000;
    border-radius: 50%;
}

.cart {
    display: block;
    position: fixed;
    width: 450px;
    min-width: 400px;
    top: 0;
    right: -450px;
    background: #fff;
    z-index: 1000;
    transition: all .5s ease;
    height: 100%;
    opacity: 0;
    overflow-x: hidden;
    touch-action: none;
}

.cart .cart-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 18px;
    padding: 20px;
}

.cart-header button {
    color: #000;
}

.cart-content {
    display: block;
    width: 100%;
    height: calc(100% - 71px);
}

.cart.active {
    right: 0;
    opacity: 1;
}

/* empty cart */
.empty-cart {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.empty-cart-info {
    width: 100%;
    padding: 20px;
}

.empty-cart-info * {
    padding: 5px;
    color: #787878
}

.empty-cart span {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
}

.empty-cart i {
    display: flex;
    justify-content: center;
    font-size: 150px;
}

.empty-cart .btn-see-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    background: #000;
    color: #fff;
    width: 100%;
    border-radius: 50px;
    height: 50px;
}

/* cart items */
.cart-item-list {
    display: block;
    width: 100%;
    height: calc(100% - 280px);
    overflow-y: scroll;
    min-height: 280px;
    padding: 0 20px;
}

.cart-item-list::-webkit-scrollbar {
    width: 12px;
}

.cart-item-list::-webkit-scrollbar-track {
    background: #f0f0f0
}

.cart-item-list::-webkit-scrollbar-thumb {
    background-color: #848484;
    border-radius: 20px;
    border: 3px solid #f0f0f0
}

.cart-item {
    display: flex;
    width: 100%;
    padding: 5px 0;
}

.cart-item img {
    height: 110px;
    margin-right: 15px;
}

.cart-item-info {
    display: block;
    width: 100%;
}

.cart-item span {
    display: block;
    color: #787878;
    font-size: 10px;
}

.cart-item .cart-item-info-top {
    color: #343434;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cart-item .cart-item-info-top i {
    color: #ff6f6f;
    font-size: 17px;
}

.cart-item .cart-item-info-bottom {
    color: #343434;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;
}

.cart-item-upgrade {
    display: flex;
}

.cart-item-upgrade span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 100;
    width: 20px;
    height: 20px;
    margin-bottom: 7px;
}

.cart-item-upgrade span:first-child {
    margin-right: 8px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    cursor: pointer;
}

.cart-item-upgrade span:last-child {
    margin-left: 8px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    cursor: pointer;
}

.cart-footer {
    display: flex;
    position: absolute;
    bottom: 0;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    height: 280px;
    padding: 20px;
}

.cart-footer * {
    width: 100%;
}

.cart-footer-inputs {
    display: block;
    font-size: 13px;
    margin-bottom: 10px;
}

.cart-input {
    display: flex;
    align-items: center;
}

.cart-input span {
    display: flex;
    float: right;
    justify-content: flex-end;
    padding: 0 10px 0 0;
}

.cart-input .input-box .cart-input {
    height: 15px;
}

.cart-footer-title {
    font-weight: bold;
}

.cart-footer-content-line {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 15px;
    color: #787878;
}

.cart-footer-content-line:first {
    display: flex;
    justify-content: flex-start;
}

.cart-footer-content-line span:last-child {
    display: flex;
    justify-content: flex-end;
}

.cart-footer-content-line.cart-total {
    color: #000;
    margin: 20px 0;
    font-weight: bold;
}

.btn-finalize {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    border-radius: 50px;
    font-size: 1rem;
}

.input-box {
    margin: 0;
    /* width: 100%; */
    height: 45px;
    padding: 3px 0;
    /* margin-right: 10px; */
}

.input-box .react-datepicker-wrapper {
    width: 100%;
}

.input-box .react-datepicker__input-container {
    margin: 0;
    height: 45px;
    padding: 3px 0;
    width: 100%;
}

.react-datepicker__view-calendar-icon input {
    padding: 0 35px 0 20px;
}

.input-field {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    padding: 0 35px 0 20px;
    font-size: 15px;
    background: #000000;
    color: #fff;
    text-decoration-color: #fff;
    border: 1px #fff solid;
}

.textarea-field {
    width: 100%;
    resize: none;
    margin-top: 5px;
    border-radius: 20px;
    font-size: 15px;
    color: #787878;
    text-decoration-color: #787878;
    /* min-height: 100px; */
    padding: 10px 20px 0 20px;
}

.textarea-field::placeholder {
    color: #787878;
}

.input-field.light {
    color: #787878;
    background: #fff;
    text-decoration-color: #787878;
    border: 1px #787878 solid;
}

.input-field.light::placeholder {
    color: #787878;
}

.input-field.invalid {
    border: 2px solid #ff4e4e;
}

.input-field.invalid::placeholder {
    color: #ff4e4e;
}

.textarea-field.invalid {
    border: 2px solid #ff4e4e;
}

.textarea-field.invalid::placeholder {
    color: #ff4e4e;
}

.input-button {
    position: relative;
    left: calc(100% - 32px);
    bottom: 32px;
    /* left: calc(100% - 30px);
    bottom: calc(100% - 6px); */
}

.react-datepicker__calendar-icon {
    right: 5px;
    color: #787878;
}

.input-button.light {
    color: #787878;
}

.input-box-mobile {
    z-index: 50;
    display: flex;
    position: fixed;
    width: 100%;
    height: 50px;
    padding-top: 100px;
    padding-bottom: 55px;
    background: black;
    display: none;
    box-shadow: 0 0.1px 15px black;
}

@media (max-width: 1100px) {
    .header {
        padding-bottom: 155px;
    }

    header {
        box-shadow: none;
    }

    .info {
        font-size: 13px;
    }

    .menu {
        display: block;
        position: absolute;
        width: 100%;
        top: 70px;
        right: 0px;
        background: #000000;
        z-index: 990;
        transition: all .5s ease;
        opacity: 0;
        height: 0px;
        visibility: hidden;
        overflow-y: hidden;
    }

    .nav.active .menu {
        height: calc(100vh - 100px);
        /* height: calc(312.5px); */
        visibility: visible;
        overflow-y: hidden;
        opacity: 1;
    }

    .menu a {
        color: #fff;
        padding: 1rem 0;
        margin: 0 1rem;
        border-bottom: 2px solid rgba(0, 0, 0, .05);
    }

    .btn-mobile {
        color: #fff;
        display: flex;
        padding: .5rem .5rem;
        font-size: 1rem;
        border: none;
        background: none;
        cursor: pointer;
        gap: .5rem;
    }

    .btn-cart {
        padding: .5rem .5rem;
    }

    .btn-user {
        display: none;
    }

    .btn-user-mobile {
        display: flex !important;
    }

    .btn-user-mobile.dropdown {
        display: block !important;
    }

    .badge-cart-items {
        right: 18px;
    }

    .hamburger {
        border-top: 2px solid;
        width: 20px;
    }

    .hamburger::before,
    .hamburger::after {
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        background: currentColor;
        margin-top: 5px;
        transition: .3s;
        position: relative;
    }

    .nav.active .hamburger {
        border-top-color: transparent;
    }

    .nav.active .hamburger::before {
        transform: rotate(135deg);
    }

    .nav.active .hamburger::after {
        transform: rotate(-135deg);
        top: -7px;
    }

    .dropdown-btn {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        gap: 2rem;
        margin: 0 1.5rem 0 1rem;
        z-index: 1000;
    }

    .dropdown-options {
        position: relative;
        border-radius: 0;
        background: #3f3f3f;
        padding: 0;
        max-height: 235px;
    }

    .dropdown-options a {
        border-radius: 0;
        margin: 0;
        padding-left: 2rem;
    }

    .dropdown-options a:hover {
        color: #fff;
        background: #3f3f3f;
    }

    .dropdown:hover .dropdown-icon {
        transform: rotate(0deg);
    }

    .dropdown:hover .dropdown-options {
        display: none;
    }

    .dropdown.show .dropdown-options {
        display: block;
    }

    .cart {
        width: 100%;
        right: -100%;
        min-width: auto;
    }

    .header .buttons {
        margin-left: 0;
    }

    .header .text-icon {
        width: auto;
    }

    .buttons .input-box {
        display: none;
    }

    .input-box-mobile .input-box {
        padding: 3px 20px;
    }

    .input-box-mobile {
        display: block;
    }
}

/********* BANNER *********/

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.banner .banner-content {
    display: block;
    position: relative;
    width: 100%;
}

.banner .banner-content .btn-form {
    border: 2px #949494 solid;
    box-shadow: 0 1.5px 5px black;
    cursor: pointer;
    max-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner img {
    width: 100%;
    height: calc(75vh - 155px);
    object-fit: cover;
}

.banner-opacity {
    background: #000;
    display: block;
    position: absolute;
    width: 100%;
    height: calc(75vh - 155px);
    z-index: 1;
    opacity: 0.2;
}

.banner-label {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    color: #fff;
    align-items: center;
    z-index: 1;
    overflow: hidden;
}

.banner-label-content {
    display: block;
    padding: 50px;
    margin-left: 10%;
    max-width: 650px;
    opacity: 0;
    transform: translateX(-50px);
}

.banner-label-content.active {
    transition: 1s all ease;
    opacity: 1;
    transform: translateX(0);
}

.banner-label-title {
    font-size: 45px !important;
    font-weight: bold !important;
    padding-bottom: 20px;
    text-shadow: 0 3px 5px rgb(32, 32, 32) !important;
}

.banner-label-content label {
    display: block;
    font-size: 30px;
    text-shadow: 0 8px 8px black;
    font-family: 'Cordel Encarnado II';
    cursor: pointer;
}

.slider-buttons {
    display: flex;
    position: absolute;
    align-items: center;
    width: 100%;
    height: 100%;
}

.slider-prev-btn {
    display: flex;
    position: absolute;
    z-index: 5;
    right: 0;
    color: #000;
    opacity: 0.5;
    background: none;
    border: none;
    max-height: 45px;
    cursor: pointer;
}

.slider-prev-btn:hover {
    color: #fff;
    background: #000;
}

.slider-next-btn {
    position: absolute;
    z-index: 5;
    color: #000;
    opacity: 0.5;
    background: none;
    border: none;
    max-height: 45px;
    cursor: pointer;
}

.slider-next-btn:hover {
    color: #fff;
    background: #000;
}

.slider-prev-btn i {
    font-size: 45px;
    font-weight: bold;
}

.slider-next-btn i {
    font-size: 45px;
    font-weight: bold;
}


/********* FILTERS *********/


.filters {
    padding: 40px 20px 0px 20px;
}

.filter-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.filter-header {
    display: flex;
    width: 100%;
    max-width: 2000px;
    flex-wrap: wrap;
}

.filter-buttons {
    display: flex;
    width: 70%;
    gap: 10px;
}

.filter-links {
    display: flex;
    width: 30%;
    justify-content: flex-end;
    /* gap: 20px; */
}

.filter-links.mobile {
    display: none;
}

.filter-buttons button {
    background: #fff;
    color: #000;
    border: 2px #000 solid;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    max-height: 40px;
    margin: 0;
}

.filter-buttons button.active {
    background: #000;
    color: #fff;
}

.filter-links a {
    background: #fff;
    color: #c5c5c5;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.filter-links a.active {
    color: #000;
}

.filter-links a i {
    font-size: 40px;
}

.filter-content {
    display: flex;
    width: 100%;
    padding: 20px 4%;
    background: #cacaca;
    flex-wrap: wrap;
    border-radius: 8px;
    margin-top: 20px;
    max-width: 2000px;
}

.filter-column {
    width: 25%;
}

.filter-column .filter-column-title {
    padding: 20px 0;
    font-weight: bold;
    font-size: 20px;
}

.filter-column .filter-column-item {
    padding: 7px 0;
    list-style: none;
    font-size: 18px;
}

.filter-column-item a {
    color: #626262;
    border-radius: 8px;
    padding: 8px 10px;
}

.filter-column-item.selected a {
    background: #000;
    color: #fff;
}

.filter-column-item .input-price input {
    display: block;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.filter-column-item .input-price label {
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 20px;
}

.filter-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
}

.filter-footer button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
}

.filter-footer button.light {
    background: #787878;
    color: #fff;
}

@media (max-width: 750px) {
    .filter-content {
        padding: 20px 20px;
    }

    .filter-column {
        width: 100%;
    }

    .filter-links {
        display: none;
    }

    .filter-links.mobile {
        display: flex;
    }
}

/********* PRODUCTS *********/

.products {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 10px;
}

.product-item {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 20px;
}

.products.one-column .product-item {
    width: 100% !important;
}

.products.two-column .product-item {
    width: 50% !important;
}

.products.four-column .product-item {
    width: 25% !important;
}

.product-item.disabled {
    opacity: 0.5;
    cursor: default;
}

.product-item .stock-zero {
    position: absolute;
    top: 10px;
    right: 0;
}

.product-item .stock-zero p {
    display: flex;
    width: 100%;
    background: #000;
    color: #fff;
    padding: 0 10px;
}

.product-info {
    display: block;
    width: 100%;
}

.product-info span {
    display: flex;
    width: 100%;
    justify-content: center;
}

.product-item a {
    color: #000000;
}

.product-item.disabled a {
    cursor: default;
}

.img-wrapper {
    display: inline-block;
    overflow: hidden;
}

.product-item img {
    width: 100%;
    max-width: 350px;
    transition: all 0.2s ease;
    vertical-align: middle;
}

.product-item img:hover {
    transform: scale(1.12);
}

.product-item.disabled img:hover {
    transform: none;
}

.info-title {
    text-shadow: 0.05em 0.05em 0.05em #efefef;
}

.info-price {
    font-weight: bold;
    font-size: 20px;
    gap: 5px;
    align-items: flex-end;
}

.info-price p {
    font-size: 15px;
    font-weight: 100;
    padding: 2px;
    color: #6d6d6d;
}

.info-condition {
    color: #6d6d6d;
    font-size: 13px;
}

@media (max-width: 750px) {
    .product-item {
        width: 50%;
        padding: 0;
        margin-bottom: 25px;
    }

    .info-condition {
        font-size: 11px;
    }

    .banner-label-content {
        margin-left: 0%;
    }

    .banner-label-content label {
        font-size: 20px;
    }
}

/********* USER TRANSACTION *********/

.user-transaction-list {
    color: #343434;
    padding: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.user-transaction-content {
    width: 1000px;
}

.user-transaction-item {
    padding: 20px;
    border: 1px #b8b8b8 solid;
    border-radius: 20px;
    margin: 20px 0;
    max-width: 1000px;
}

.user-transaction-item .transaction-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #c5c5c5;
}

.user-transaction-item .transaction-item-title label:first-child {
    color: #000;
}


/********* FOOTER *********/

.footer {
    color: #fff;
    width: 100%;
    background: #000000;
}

.footer-content {
    width: 100%;
    display: flex;
    padding: 95px 35px 95px 35px;
    flex-direction: row;
}

.footer-column {
    width: 100%;
    padding: 0 3%;
}

.footer-column h4 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
}

.footer-column p {
    padding: 15px 0;
}

.footer-column ul {
    list-style: none;
}

.footer-column ul li {
    font-size: 17px;
    padding: 3px;
}

.footer-column .social-media {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    gap: 20px;
}

.footer-column .social-media a {
    /* width: 100%; */
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3f3f3f;
}

.payment-icons {
    display: block;
}

.footer-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-top: 1px solid #828282;
    padding: 35px 20px;
}

.footer-copyright span {
    display: flex;
    justify-content: center;
    font-size: 100%;
}

.btn-to-top {
    display: none;
}

.btn-to-top button {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    position: fixed;
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 70px;
    background: #6d6d6d;
    color: #000;
    border-radius: 50px;
    border: 2px #000 solid;
    cursor: pointer;
}

.btn-to-top button:hover {
    background: #949292;
}

.btn-to-top i {
    font-size: 50px;
}

@media (max-width: 1100px) {
    .footer-content {
        display: block;
        padding: 95px 20px 95px 20px;
    }

    .footer-column {
        padding: 8px 0px;
    }

    .footer-copyright span {
        font-size: 70%;
    }

    .btn-to-top button {
        width: 65px;
        height: 65px;
    }

    .footer-column .social-media {
        justify-content: flex-start;
    }
}

/* FORM */
.form-container {
    background: #e7e7e7;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.form-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #fff;
    max-width: 700px;
}

.form-content {
    width: 100%;
    padding-bottom: 20px;
}

@media (max-height: 750px) {
    .form-box.break {
        align-items: flex-start;
    }
}

.form-content p {
    font-size: 12px;
}

.forgot-passord-info {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 0 20px;
    margin-bottom: 70px;
}

.forgot-passord-info span {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    text-align: center;
}

.form-input {
    margin: 20px 60px;
}

.form-input span {
    font-weight: bold;
    font-size: 15px;
}

.form-input .checkbox {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
}

.form-input .checkbox input {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
}

.logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    width: 100%;
    height: 80px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    padding: 20px;
    text-align: center;
}

.btn-form {
    background: #000;
    width: 100%;
    border-radius: 50px;
    height: 50px;
    margin-top: 20px;
}

.form-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-links a {
    font-weight: bold;
    font-size: 13px;
    color: #7d7d7d;
    padding: 8px;
    text-decoration: underline;
}

@media (max-width: 1400px) {
    .form-box {
        width: 100%;
    }

    .form-input {
        margin: 20px;
    }
}

.modal {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    visibility: hidden;
    transition: 0.2s all ease;
    padding: 20px;
    z-index: 999;
    opacity: 0;
}

.modal.show {
    opacity: 1;
    visibility: visible;
}

.modal-block-touch {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    padding: 20px;
    z-index: 998;
    touch-action: none;
}

.modal .modal-content {
    width: 100%;
    max-width: 800px;
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
    height: 500px;
    box-shadow: 0px 0px 6px #000;
    transition: 0.2s all ease;
    transform: scale(0.2);
}

.modal.show .modal-content {
    transform: scale(1);
}

.modal-content .modal-header {
    display: flex;
    padding: 20px 0;
    border-bottom: 2px #f1f1f1 solid;
}

.modal-content .modal-header button {
    color: #000;
}

.information-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.information-screen .information-content {
    padding: 20px;
    width: 100%;
    max-width: 1300px;
}

.information-screen .information-content a {
    color: #000;
    font-weight: bold;
    text-decoration: underline;
}

.information-screen .information-content li {
    list-style: disc inside;
}

.information-screen .information-content h1 {
    margin-bottom: 40px;
}

.information-screen .information-content h2,
.information-screen .information-content p,
.information-screen .information-content ul {
    margin: 20px 0;
}